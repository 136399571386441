<template>
  <div class="flex flex-col items-center px-2">
    <div
      class="flex whitespace-nowrap text-center text-lg font-semibold"
      v-if="!insetLabel && (label || $slots['label'])"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </div>
    <div :class="['score-outer', size]">
      <div class="-mt-[5px] flex flex-col items-center" v-if="insetLabel">
        <div :class="['score-value', size]">{{ displayedScore }}</div>
        <div class="inset-label -mt-3" v-if="insetLabel">{{ label }}</div>
      </div>
      <div :class="['score-value', size]" v-else>{{ displayedScore }}</div>
      <div :class="['track', size, usedThickness]"></div>
      <div :class="['progress-wheel', size, usedThickness]" :style="wheelStyle"></div>
      <div
        :class="['progress-wheel', size, usedThickness]"
        :style="{
          ...wheelStyle,
          transform: `rotate(${partialSegmentRotation}deg)`
        }"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { linearProjection } from '~/utils/math'

  const props = defineProps<{
    score: number
    label?: string
    size?: 'small' | 'normal' | 'large'
    bandThickness?: 'thin' | 'normal'
    overrideColor?: string
    insetLabel?: boolean
  }>()

  const { flag } = FeatureFlags.use()

  const scoreConfig = computed(() => flag('plan-score-config').value)

  const usedThickness = computed(() => `band-${props.bandThickness}`)

  const scoreColor = computed(() => {
    if (!!props.overrideColor) {
      return props.overrideColor
    }

    const idx = _findLastIndex(scoreConfig.value.tiers, (val) => val.min <= props.score)

    return idx === -1
      ? _last(scoreConfig.value?.tiers)?.level
      : scoreConfig.value?.tiers?.[idx]?.level
  })

  const displayedScore = computed(() => _clamp(props.score, 0, 99))

  const totalDegrees = computed(() => 360 * (props.score / 100))

  const wheelStyle = computed(() => ({
    borderTopColor: totalDegrees.value < 90 ? 'transparent' : scoreColor.value,
    borderRightColor: totalDegrees.value < 180 ? 'transparent' : scoreColor.value,
    borderBottomColor: totalDegrees.value < 270 ? 'transparent' : scoreColor.value,
    borderLeftColor: totalDegrees.value < 360 ? 'transparent' : scoreColor.value,
    transform: `rotate(45deg)`
  }))

  const partialSegmentRotation = computed(() => {
    const addedDegrees = totalDegrees.value - _floor(totalDegrees.value / 90) * 90

    return linearProjection(addedDegrees, 0, 90, 45, 135)
  })
</script>

<style scoped>
  .progress-wheel {
    @apply absolute h-[60px] w-[60px] rounded-full border-[6px];

    &.small {
      @apply h-[50px] w-[50px];
    }

    &.large {
      @apply h-[70px] w-[70px];
    }

    &.band-thin {
      @apply border-[5px];
    }
  }

  .score-outer {
    @apply relative flex h-[60px] items-center justify-center;

    &.small {
      @apply h-[50px];
    }

    &.large {
      @apply h-[70px];
    }
  }

  .track {
    @apply absolute h-[60px] w-[60px] rounded-full border-[6px] border-gray-300;

    &.small {
      @apply h-[50px] w-[50px];
    }

    &.large {
      @apply h-[70px] w-[70px];
    }

    &.band-thin {
      @apply border-[5px];
    }
  }

  .score-value {
    @apply text-4xl font-bold tracking-tighter;

    &.small {
      @apply text-3xl;
    }
  }

  .inset-label {
    @apply h-min text-sm font-semibold tracking-tight;
  }
</style>
